import NextImage from 'next/image'
import { ReactNode } from 'react'
import styled from 'styled-components'

import { Container } from 'components/blocks/sections/directories/AllEntries/ServiceEntries'
import { Icon, IconOptions } from 'components/ui/Icon'
import { Text } from 'components/ui/deprecated/Text'
import { ConversionStoryblok } from 'lib/storyblok/types'
import {
  getStoryblokImageAttributes,
  hasValidLink,
  textByLine,
} from 'lib/utils/content'
import { cn } from 'lib/utils/tailwind'

import { addAlphaToColor } from '../../../lib/style'
import { IconHolder } from '../../ui/deprecated/IconHolder'
import { Theme } from '../../ui/deprecated/Theme'
import { CMSLink } from './CMSLink'

type Props = {
  block: ConversionStoryblok
}

const ConversionWrapper: React.FC<{
  children: ReactNode
}> = ({ children, ...props }) => {
  return (
    <CardWrapper as="section" variant="typography" {...props}>
      <Theme colorTheme="light" className="bg-transparent">
        {children}
      </Theme>
    </CardWrapper>
  )
}

export const Conversion: React.FC<Props> = ({ block, ...props }) => {
  const {
    title,
    button_text,
    image_position,
    button_link,
    description,
    image_source,
    variant,
    button_icon,
  } = block

  return (
    <ConversionWrapper {...props}>
      <Wrapper
        conversionVariant={variant}
        reverseDirection={image_position === 'end'}
      >
        {image_source?.filename && (
          <ImageHolder>
            <NextImage
              src={getStoryblokImageAttributes(image_source).src}
              alt={getStoryblokImageAttributes(image_source).alt}
              fill
              style={{ objectFit: 'cover', height: '100%' }}
            />
          </ImageHolder>
        )}
        <ContentHolder>
          <Content noImage={!!image_source?.filename}>
            {title && (
              <Heading as="h2" variant="title/small/regular">
                {textByLine(title, (part, i, length) => {
                  return (
                    <>
                      {part}
                      {i + 1 < length && <br />}
                    </>
                  )
                })}
              </Heading>
            )}
            {description &&
              textByLine(description, (part) => (
                <Text as="p" variant="twenty">
                  {part}
                </Text>
              ))}
            {(button_text || button_icon) &&
              hasValidLink(button_link) &&
              button_link && (
                <ButtonHolder>
                  <CMSLink href={button_link} variant="ghost">
                    {button_icon && (
                      <IconHolder className={cn(button_text && 'mr-2')}>
                        <Icon icon={button_icon as IconOptions} />
                      </IconHolder>
                    )}
                    {button_text}
                  </CMSLink>
                </ButtonHolder>
              )}
          </Content>
        </ContentHolder>
      </Wrapper>
    </ConversionWrapper>
  )
}

const CardWrapper = styled(Container)`
  margin: 1.5rem auto;

  ${({ theme }) => theme.media.lg} {
    margin: 2.5rem auto;
  }
`

const Wrapper = styled(Container)<{
  conversionVariant: ConversionStoryblok['variant']
  reverseDirection: boolean
}>`
  position: relative;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  padding: 0;
  border-radius: 16px;
  flex-direction: ${({ reverseDirection }) =>
    reverseDirection ? 'column-reverse' : 'column'};

  ${({ theme }) => theme.media.md} {
    flex-direction: ${({ reverseDirection }) =>
      reverseDirection ? 'row-reverse' : 'row'};
  }

  background-color: ${({ theme }) =>
    addAlphaToColor(theme.colors.foreground.default, 6)};
`

const ContentHolder = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const ImageHolder = styled.div`
  position: relative;
  height: 15rem;
  width: auto;
  margin: 0.5rem;
  border-radius: 0.75rem;
  overflow: hidden;

  ${({ theme }) => theme.media.md} {
    height: auto;
    width: 15.625rem;
  }

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
  }
`

const ButtonHolder = styled.div`
  padding-top: 2rem;
`

const Content = styled.div<{ noImage?: boolean }>`
  padding: 1.5rem;

  ${({ theme }) => theme.media.md} {
    padding: 2rem;
    max-width: ${({ noImage }) => (noImage ? '22.75rem' : 'unset')};
  }
`

const Heading = styled(Text)`
  margin-bottom: 1rem;
`
